import { graphql, Link } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import SearchPill from "../components/SearchPill"


const Notes = ({data}) => {
    const allPosts = data.allMarkdownRemark.edges
    
    const emptyQuery = ""
    
    const [state, setState] = useState({
        filteredData: [],
        query: emptyQuery
    })


    const handleInputChange = e => {
        const query = e.target.value || e.currentTarget.innerHTML
        const posts = data.allMarkdownRemark.edges 
        
        const filteredData = posts.filter(post => {

            const { description, title, tags } = post.node.frontmatter
            const { html } = post.node

            console.log(html)

            return (
                html.toLowerCase().includes(query.toLowerCase()) ||
                description.toLowerCase().includes(query.toLowerCase()) ||
                title.toLowerCase().includes(query.toLowerCase()) ||
                (tags && tags.join("").toLowerCase().includes(query.toLowerCase()))
                )
            })
            
            setState({
                query,
                filteredData
            })
    }
    
    const hasSearchResults = state.filteredData && state.query !== emptyQuery

    const posts = hasSearchResults ? state.filteredData : allPosts

    return (
        <Layout>
            <SEO title="Notes" />
            <h1 className="font-bold text-center">
                <span style={highlightText}>Notes | Code Bank</span>
            </h1>
            <div className="text-center">
                <input type="text" placeholder="Type here to search notes" onChange={handleInputChange} className="w-4/5 md:w-1/2 xl:w-1/3 text-center border-b border-primary-300 my-6 focus:outline-none focus:border-primary-500" />
            </div>
            <div className="flex flex-wrap justify-center">
                <SearchPill handleInputChange={handleInputChange} query="JavaScript" />
                <SearchPill handleInputChange={handleInputChange} query="Python" />
                <SearchPill handleInputChange={handleInputChange} query="TypeScript" />
                <SearchPill handleInputChange={handleInputChange} query="AWS" />
                <SearchPill handleInputChange={handleInputChange} query="Algorithm" />
                <SearchPill handleInputChange={handleInputChange} query="CSS" />
            </div>
            <div className="flex justify-center flex-wrap mt-3 mb-10 ">
                {state.filteredData.length === 0 && state.query !== emptyQuery ? (
                    <p className="font-bold mt-6" >Oops! No results...</p>
                ) : (
                    posts.map(({ node }) => {
                const id = node.id
                const { title, date, path, tags, description } = node.frontmatter
                    
                return (
                    <div className="border border-primary-100 rounded-sm w-screen m-2 p-3 md:w-1/4" key={id}>
                        <Link to={path}>
                            <h3 className="font-bold"><span style={highlightText}>{title}</span></h3>
                        </Link>
                        <p className="text-sm">Posted on {date}</p>
                        <p className="mt-2">{description}</p>
                        <div className="flex flex-wrap mt-3">
                        {tags.map((tag,i) => (
                            <span key={i} className="flex-grow-0 text-sm rounded-full py-1 px-2 mx-1 md:mx-0 bg-primary-50">
                                {tag}
                            </span>
                        ))}
                        </div>
                    </div>
                    )
            }))}
            </div>
        </Layout>
    )
}
export const pageQuery = graphql`
    query NotesIndexQuery{
        allMarkdownRemark (sort: { order: DESC, fields: frontmatter___date }){
            edges {
                node {
                    id
                  	html
                    frontmatter {
                    title
                    path
                    date(formatString: "DD MMMM, YYYY")
                    tags
                    description
                    }
                }
            }
        }
    }
`

const highlightText = {
    background: "linear-gradient(to top, #ffff00cb 50%, transparent 50%)"
}

export default Notes;